.toast-container{
    z-index: 9999;
    max-height: 100%;
    overflow-y: auto;
    pointer-events: auto;
}

.toast-body{
    background-color: rgba(255,255,255, 0.75);
}

.progress{
    height: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@keyframes timeOut {
    0% {
        transform: scaleX(1);
        transform-origin: left;
    }
    100% {
        transform-origin: left;
        transform: scaleX(0);
    }
}

.progress-bar{
    width: 100%;
    animation-name: timeOut;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-duration: 14s;
    animation-delay: 1s;
}

.toast:hover .progress-bar, .toast.paused .progress-bar{
    animation-play-state: paused;
}

.toast-header strong{
    text-transform: capitalize;
}


