.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(34,87,191);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.client .page-wrapper{
  margin-left: 0;
  margin-top: 0;
  padding: 0;
}

.client .page-content{
  padding: 0 1rem 50px 1rem
}

.overlay-dark{
  background-image: none;
  background-color: rgba( 0, 0, 0, 0.5 );
  z-index: 1;
  visibility: visible;
  color: white;
}

.section-authentication-signin{
  color: #32393f;
}

.grecaptcha-badge{
  z-index: 2000;
}